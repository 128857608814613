import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import {ToastContainer, toast, ToastOptions} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import MainHeader from "~components/PublicHeader/publicHeader"
import imgHomemPescando from '../../assets/images/ImgHomemPescando.png'
import logoEuPescador from '../../assets/images/logo-eu-pescador.png'
import imgProEspecies from '~assets/icons/ProEspecies.png'

import imgWwf from '~assets/icons/WWF.png'
import imgFunbio from '~assets/icons/FUNBIO.png'
import imgGef from '~assets/icons/GEF.png'
import imgMinisterioMeioAmbiente from '~assets/icons/MinisterioMeioAmbiente.png'
import imgGovFederalBrasil from '~assets/icons/GovFederalBrasil.png'

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export default function LadingPage() {
  const navigate = useNavigate()


  return (
    <Grid container>
      <MainHeader/>
      <Grid container component="main" sx={{margin: 0, padding: 0}}>
        <Grid item xs={12} sm={4} md={7.5}
              container
              direction="row"
              justifyContent="left"
              alignItems="center">
          <img style={{height: '30vh', marginLeft: '10%', zIndex: '-3'}} src={logoEuPescador} alt="Logo Eu Pescador"/>
          <img style={{height: '100vh', marginTop: '13vh' ,position: 'fixed', zIndex: '-2'}} src={imgHomemPescando} alt="Peixe"/>
        </Grid>

        <Grid item xs={12} sm={8} md={4}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <Box>
              <Typography component="h1" variant="h3" sx={{
                fontWeight: '900',
                fontSize: '42',
                color: '#3D4C76',
                textAlign: 'center'
              }}>
                COLABORE REGISTRANDO OS PEIXES DA SUA REGIÃO!
              </Typography>
            </Box>

            <Box sx={{height: '25%'}}>

            </Box>
            <Box>
              <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <img style={{width: '40%', marginBottom: '20px'}} src={imgProEspecies} alt=""/>
              </Box>

              <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <img style={{height: '35px'}} src={imgWwf} alt=""/>
                <img style={{height: '35px'}} src={imgFunbio} alt=""/>
                <img style={{height: '35px'}} src={imgProEspecies} alt=""/>
                <img style={{height: '35px'}} src={imgMinisterioMeioAmbiente} alt=""/>
                <img style={{height: '35px'}} src={imgGovFederalBrasil} alt=""/>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
