/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  TextField,
  Switch,
  FormGroup,
  FormControlLabel, Container,
} from '@mui/material'
import {TitlePage} from '~components/TitlePage/TitlePage'
import {GetOneUser, UserI} from '~services/api/userServices/getOneUser'
import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {UpdateUser} from '~services/api/userServices/UpdateUser'
import {toast} from 'react-toastify'
import AdminHeader from "~components/AdminHeader/adminHeader"
import Footer from "~components/Footer/Footer"

export interface UserProps {
  admin: boolean
  name: string
  token: string
}

const InputLabel = {
  color: '#3d4c76',
  fontWeight: 'bold',
  margin: '2px'
}
const TextInputStyle = {
  borderRadius: '8px',
  color: '#6b6b6b',
  backgroundColor: '#ffffff',
  width: '500px'
}

export default function UserForm() {
  const [user, setUser] = useState({} as UserI)
  const {id} = useParams()

  const getLog = async (logId: string) => {
    if (user) {
      const user: UserProps = JSON.parse(
        localStorage.getItem('UserData')
      ) as UserProps
      const data = await GetOneUser(logId, user && user.token)
      setUser(data)
    }
  }
  const navigate = useNavigate()
  const routeChange = () => {
    const path = '/usuarios'
    navigate(path)
  }

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getLog(id)
    }
  }, [id])
  const handleSubmit = async () => {
    try {
      const userprp: UserProps = JSON.parse(
        localStorage.getItem('UserData')
      ) as UserProps
      await UpdateUser(id, userprp.token, user)
      toast.success('usuário editado com successo!')
      routeChange()
    } catch (error) {
      toast.error('Algo deu errado, tente novamente!')
    }
  }
  return (
    <div style={{backgroundColor: '#e8e8e8'}}>
      <AdminHeader/>

      <Container>
        <Grid item xs={11}>
          <TitlePage title="Alterar Usuário"/>
          {user && Object.keys(user).length > 0 && (
            <Box
              component="form"
              noValidate
              sx={{mt: 1, width: '95%'}}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <p style={InputLabel}>Nome</p>
                  <TextField
                    margin="normal"
                    required
                    id="nome"
                    name="Nome"
                    autoComplete="Nome"
                    value={user.name}
                    onChange={(e) => {
                      setUser({...user, name: e.target.value})
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {color: '#3d4c76', fontWeight: 'bold'},
                    }}
                    InputProps={{
                      style:TextInputStyle
                      ,
                    }}
                  /></Box>

                <Box>
                  <p style={InputLabel}>Estado</p>
                  <TextField
                    margin="normal"
                    required
                    id="Estado"
                    name="Estado"
                    value={user.state}
                    onChange={(e) => {
                      setUser({...user, state: e.target.value})
                    }}
                    autoComplete="Estado"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: '#0095D9',
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      style:TextInputStyle
                      ,
                    }}
                  /></Box>

              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <p style={InputLabel}>E-mail</p>
                  <TextField
                    margin="normal"
                    required
                    id="email"
                    name="email"
                    autoComplete="email"
                    value={user.email}
                    onChange={(e) => {
                      setUser({...user, email: e.target.value})
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {color: '#3d4c76', fontWeight: 'bold'},
                    }}
                    InputProps={{
                      style:TextInputStyle
                      ,
                    }}
                  />
                </Box>

                <Box>
                  <p style={InputLabel}>Telefone</p>
                  <TextField
                    margin="normal"
                    required
                    id="telefone"
                    name="Telefone"
                    value={user.phone}
                    onChange={(e) => {
                      setUser({...user, phone: e.target.value})
                    }}
                    autoComplete="Telefone"
                    InputLabelProps={{
                      shrink: true,
                      style: {color: '#0095D9', fontWeight: 'bold'},
                    }}
                    InputProps={{
                      style:TextInputStyle
                      ,
                    }}
                  />
                </Box>
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <p style={InputLabel}>Cidade</p>
                  <TextField
                    margin="normal"
                    required
                    id="cidade"
                    name="cidade"
                    value={user.city}
                    onChange={(e) => {
                      setUser({...user, city: e.target.value})
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {color: '#0095D9', fontWeight: 'bold'},
                    }}
                    InputProps={{
                      style:TextInputStyle
                      ,
                    }}
                  />
                </Box>

                <FormGroup style={{width: '500px'}}>
                  <FormControlLabel
                    control={
                      <Switch
                        color='success'
                        checked={user.admin}
                        onChange={() => {
                          setUser({...user, admin: !user.admin})
                        }}
                      />
                    }
                    label="Administrador"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        color='success'
                        checked={user.superAdmin}
                        onChange={() => {
                          setUser({...user, superAdmin: !user.superAdmin})
                        }}
                      />
                    }
                    label="Super Admim"
                  />
                </FormGroup>
              </Box>

              <Box display="flex" justifyContent="center" mt="100px">
                <Button
                  onClick={routeChange}
                  variant="contained"
                  disableElevation
                  sx={{
                    m: 2,
                    backgroundColor: 'red',
                    borderRadius: '8px',
                    border: '4px solid white',
                    height: '50px',
                    width: '350px',
                    textTransform: 'capitalize',
                    fontSize: '18px',
                    fontWeight: '900'
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  disableElevation
                  sx={{
                    m: 2,
                    backgroundColor: '#3d4c76',
                    borderRadius: '8px',
                    border: '4px solid white',
                    height: '50px',
                    width: '350px',
                    textTransform: 'capitalize',
                    fontSize: '18px',
                    fontWeight: '900'
                  }}
                >
                  Salvar
                </Button>
              </Box>
            </Box>
          )}
        </Grid>
        <Footer />
      </Container>
    </div>
  )
}
